<template>
  <div class="main align-center mw-md">
    <el-card class="margin-15">
      <div class="mw-500">
        <p>For your security we’ll send a verification code to confirm your identity.</p>
        <p>How do you wish to verify? (select one)</p>

        <CheckBoxLine v-model="selected" val="mobile" @input="leaveLast" v-if="obj.mobile">
          <p>Send verification code to {{ obj.mobile }}</p>
        </CheckBoxLine>

        <CheckBoxLine v-model="selected" val="email" @input="leaveLast" v-if="obj.email">
          <p>Send verification code to {{ obj.email }}</p>
        </CheckBoxLine>

        <el-button type="primary" @click="submit" class="full-width" :disabled="!selected[0]">
          Send Code
        </el-button>
      </div>
    </el-card>

    <div class="align-center align-text-center">
      <a href="https://www.playrugbyleague.com/mysideline/update-profile-details/" target="_blank">
        Click here if there are no contacts listed, OR you no longer have access to these contacts.
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CheckBoxLine from "@/components/form/fields/CheckBoxLine.vue";
import store from "@/store";

export default {
  name: "Verification",
  components: { CheckBoxLine },
  computed: {
    ...mapGetters("verify", { obj: "realVerificationObject" }),
    ...mapGetters("verify", ["userRequiresVerification"]),
    ...mapState("verify", ["codeSent", "linkProfileVerificationObj"]),
  },
  beforeRouteEnter(to, from, next) {
    // navigation guard - in component

    // if code already sent => skip to next screen
    if (store.state.verify.codeSent) {
      next({ name: "verificationcode", query: from.query });
      return;
    }

    // 1. if user is not verified => dont do nothing
    if (store.getters["verify/userRequiresVerification"]) {
      next();
      return;
    }

    // if verification object is not stored (do not use persistent storage on this one)
    // go to login redirect if exists, or regotype
    if (!store.state.verify.linkProfileVerificationObj.id) {
      next({ name: store.getters["views/loginRedirect"] || "regotype" });
      store.dispatch("views/clearLoginRedirect");
      return;
    }

    next();
  },
  methods: {
    ...mapActions("verify", ["verify1"]),
    handleLostContact() {
      zE.activate();
    },
    leaveLast() {
      // only leave the last item selected
      this.selected = this.selected.slice(-1);
    },
    submit() {
      this.verify1({
        vm: this,
        mobileSelected: this.selected[0] === "mobile",
      });
    },
  },
  data() {
    return {
      selected: [],
      error: null,
    };
  },
};
</script>
